bcl.s.hotelReturn = {
  props: {
    cssHotelSelector: "hotel-return-JS",
    localStorageName: "barcelo_hotel_return",
    cssServiceSelector: "c-header-detail__header-back",
    attrName: "hotel",
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document,
      elementSet = baseFind.querySelector("." + bcl.s.hotelReturn.props.cssHotelSelector);
    let isHotel = false,
      isDetail = false;

    if (elementSet) {
      isHotel = true;
      bcl.s.hotelReturn.setID(elementSet);
    }

    const elementGet = baseFind.querySelector("." + bcl.s.hotelReturn.props.cssServiceSelector);

    if (elementGet) {
      isDetail = true;
      bcl.s.hotelReturn.getID(elementGet);
    }

    if (!(isDetail || isHotel)) {
      localStorage.removeItem(bcl.s.hotelReturn.props.localStorageName);
    }
  },

  setID: function ($elementSet) {
    let output = false;

    if ($elementSet) {
      const hotelID = $elementSet.dataset[bcl.s.hotelReturn.props.attrName];

      localStorage.setItem(bcl.s.hotelReturn.props.localStorageName, hotelID);
      output = true;
    }

    return output;
  },

  getID: function ($elementGet) {
    const id = localStorage.getItem(bcl.s.hotelReturn.props.localStorageName);
    let output = false;

    localStorage.removeItem(bcl.s.hotelReturn.props.localStorageName);

    if (id && $elementGet) {
      const hotelID = $elementGet.dataset[bcl.s.hotelReturn.props.attrName];

      if (id == hotelID && window.history && window.history.lenght > 1) {
        output = true;
        $elementGet.addEventListener("click", function (e) {
          e.stopPropagation();
          e.preventDefault();

          if (window.history) {
            window.history.back();
          }
        });
      }
    }

    return output;
  },
};

bcl.u.docReady(bcl.s.hotelReturn.init, true);
