bcl.s.scrollUp = {
  props: {
    numberOfScreens: 1,
    cssArrowSelector: "showOnScroll-JS",
    $items: [],
    seeMoreWasClicked: false,
    paginationJSButton: "pagination-JS",
    paginationJSShowMore: "show-more-JS",
  },

  init: function (baseDom) {
    if (bcl.editMode) {
      return;
    }

    const baseFind = baseDom ? baseDom : document;

    const items = baseFind.querySelectorAll("." + bcl.s.scrollUp.props.cssArrowSelector);

    if (!items || !items.length) {
      return;
    }

    const footer = document.querySelector("body > footer");

    if (footer) {
      bcl.s.scrollUp.props.$footer = footer;
    }

    bcl.u.forEach(items, function (item) {
      bcl.s.scrollUp.props.$items.push(item);
    });

    bcl.s.scrollUp.initEvents();
  },

  initEvents: function () {
    window.addEventListener("scroll", function (e) {
      bcl.s.scrollUp.scrollEvent(e);
    });
  },

  scrollEvent: function () {
    const wh = window.innerHeight,
      footer = bcl.s.scrollUp.props.$footer && bcl.s.scrollUp.props.$footer.getBoundingClientRect();

    bcl.u.forEach(bcl.s.scrollUp.props.$items, function ($item) {
      let screens = $item.dataset.screens;

      if (!screens) {
        screens = bcl.s.scrollUp.props.numberOfScreens;
      }

      const minHeight = wh * screens;
      let show = false;

      if ((window.scrollY || document.documentElement.scrollTop) > minHeight) {
        show = true;
        bcl.u.removeClass($item, "hide-right");
        bcl.u.addClass($item, "show-right");

        if ($item.dataset.limitfooter && footer && footer.top < footer.height) {
          let count = $item.dataset.limitfooter;

          if (bcl.u.mobile.isMobile() && $item.dataset.limitfootermobile) {
            count = $item.dataset.limitfootermobile;
          }

          $item.style.top = footer.top - parseInt(count) + "px";
        } else {
          $item.style.top = "";
        }
      }
      if (!show) {
        bcl.u.removeClass($item, "show-right");
        bcl.u.addClass($item, "hide-right");
      }
    });

    bcl.s.scrollUp.clickOnSeeMoreSpan();
  },

  clickOnSeeMoreSpan: function () {
    const seeMoreSpan = document.querySelectorAll("." + bcl.s.scrollUp.props.paginationJSButton);
    const seeMoreSpanPag = document.querySelectorAll("." + bcl.s.scrollUp.props.paginationJSShowMore);

    if ((seeMoreSpan && seeMoreSpan.length > 0) || (seeMoreSpanPag && seeMoreSpanPag.length > 0)) {
      if (bcl.u.isElementInView(seeMoreSpan[0], false) && !bcl.s.scrollUp.props.seeMoreWasClicked) {
        bcl.u.triggerEvent(seeMoreSpan[0], "click");
        bcl.s.scrollUp.props.seeMoreWasClicked = true;
      } else if (bcl.u.isElementInView(seeMoreSpanPag[0], false) && !bcl.s.scrollUp.props.seeMoreWasClicked) {
        bcl.u.triggerEvent(seeMoreSpanPag[0], "click");
        bcl.s.scrollUp.props.seeMoreWasClicked = true;
      } else {
        bcl.s.scrollUp.props.seeMoreWasClicked = false;
      }
    }
  },
};

bcl.u.docReady(bcl.s.scrollUp.init, true);
