import datePicker from "barcelo-shared/datePicker";
import swiperMobile from "barcelo-shared/swiperMobile";
import currency from "barcelo-shared/currency";

const sharedModules = {
  datePicker,
  swiperMobile,
  currency,
};

const isAlreadyInitialized = (moduleName) => Object.prototype.hasOwnProperty.call(bcl.s, moduleName);

const BarceloShared = {
  init: () => {
    for (const name in sharedModules) {
      if (Object.prototype.hasOwnProperty.call(sharedModules, name) && !isAlreadyInitialized(name)) {
        const module = sharedModules[name];
        if (module && typeof module.init === "function") {
          module.init();
          bcl.s[name] = module;
        }
      }
    }
  },
};

export { BarceloShared };
