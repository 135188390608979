bcl.s.recaptchav3 = {
  checkRecaptcha: function (requestUrl, callback, object) {
    const action = "validateCaptcha";

    requestUrl &&
      grecaptcha.ready(function () {
        grecaptcha.execute(googleCaptchaApiKey ? googleCaptchaApiKey : "", { action }).then(function (token) {
          bcl.ajax.postRequest({
            data: {
              token,
            },
            url: requestUrl,
            callback: function (data) {
              const response = JSON.parse(data.response);
              const success = response.success ? response.success : false,
                score = response.score ? response.score : 0.0;
              if (success && score >= 0.9) {
                callback(object);
              }
            },
          });
        });
      });
  },
};
