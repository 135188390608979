import "pannellum/build/pannellum";
import "pannellum/build/pannellum.css";

bcl.s.panorama = {
  props: {
    dataAttr: "image-360",
    pannellumConfig: {},
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document,
      elements = baseFind.querySelectorAll("[data-" + bcl.s.panorama.props.dataAttr + "]");

    for (let i = 0; i < elements.length; i++) {
      bcl.s.panorama.process(elements[i]);
    }
  },

  process: function ($element) {
    $element.onload = function () {
      const image = this.src;
      if (image) {
        const height = this.height;
        const wrapper = document.createElement("div");
        bcl.u.addClass(wrapper, "panorama-wrapper");
        wrapper.style.height = height + "px";
        bcl.u.wrap($element, wrapper);
        this.remove();
        pannellum.viewer(wrapper, {
          type: "equirectangular",
          autoLoad: true,
          panorama: image,
        });
      }
    };
  },
};

bcl.u.docReady(bcl.s.panorama.init);
