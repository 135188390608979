bcl.c.mybEditProfile = {
  personalElements: {
    form: ".js-form-personal-data",
    name: ".js-input-name",
    surname: ".js-input-surname",
    birthday: ".js-input-birthday",
    phone: ".js-input-phone",
    phonePrefixParent: ".js-phone-parent",
    phonePrefix: ".iti__active",
    address: ".js-input-address",
    postalCode: ".js-input-postal-code",
    province: ".js-input-province",
    city: ".js-input-city",
    country: ".js-select-country",
  },

  modals: {
    activeClass: "tingle-modal--visible",
    successModal: ".js-success-modal",
    errorModal: ".js-error-modal",
  },

  props: {
    component: "c-myb-edit-profile-content",
    endpoint: "",
    invalidInput: ".mod--invalidate",
    info: {
      givenName: "string",
      surname: "string",
      phoneNumber: "string",
      phoneCountryPrefix: "string",
      address: "string",
      city: "string",
      postalCode: "string",
      state: "string",
      country: "string",
      birthdayDate: "string",
    },
    inputs: {},
  },

  preInit: function () {
    bcl.u.docReady(bcl.c.mybEditProfile.init);
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document;
    const $component = baseFind.getElementsByClassName(bcl.c.mybEditProfile.props.component);

    if (!$component || !$component[0]) {
      return;
    }
    bcl.c.mybEditProfile.props.endpointGet = $component[0].dataset.urlGet;
    bcl.c.mybEditProfile.props.endpointPost = $component[0].dataset.urlPost;
    bcl.c.mybEditProfile.addListeners($component[0]);
    bcl.c.mybEditProfile.setInputs($component[0]);
  },

  addListeners: function (element) {
    const personalForm = element.querySelector(bcl.c.mybEditProfile.personalElements.form);
    if (personalForm) {
      personalForm.addEventListener("submit", bcl.c.mybEditProfile.submitPersonalForm);
    }

    const inputName = element.querySelector(bcl.c.mybEditProfile.personalElements.name);
    if (inputName) {
      inputName.addEventListener("change", function () {
        bcl.c.mybEditProfile.validateInput(inputName, personalForm);
      });
    }

    const inputSurname = element.querySelector(bcl.c.mybEditProfile.personalElements.surname);
    if (inputSurname) {
      inputSurname.addEventListener("change", function () {
        bcl.c.mybEditProfile.validateInput(inputSurname, personalForm);
      });
    }

    const inputAddress = element.querySelector(bcl.c.mybEditProfile.personalElements.address);
    if (inputAddress) {
      inputAddress.addEventListener("change", function () {
        bcl.c.mybEditProfile.validateInput(inputAddress, personalForm);
      });
    }

    const inputPostalCode = element.querySelector(bcl.c.mybEditProfile.personalElements.postalCode);
    if (inputPostalCode) {
      inputPostalCode.addEventListener("change", function () {
        bcl.c.mybEditProfile.validateInput(inputPostalCode, personalForm);
      });
    }

    const inputProvince = element.querySelector(bcl.c.mybEditProfile.personalElements.province);
    if (inputProvince) {
      inputProvince.addEventListener("change", function () {
        bcl.c.mybEditProfile.validateInput(inputProvince, personalForm);
      });
    }

    const inputCity = element.querySelector(bcl.c.mybEditProfile.personalElements.city);
    if (inputCity) {
      inputCity.addEventListener("change", function () {
        bcl.c.mybEditProfile.validateInput(inputCity, personalForm);
      });
    }
  },

  validateInput: function (input, form) {
    const formato = /[`!¡¿¬¨@#$%^&*()_+\=\[\]{};':"\\|<>\/?~€]/;
    if (formato.test(input.value) || input.value.startsWith(" ") || input.value.startsWith("-") || input.value.startsWith(",") || input.value.startsWith(".")) {
      bcl.u.addClass(input, "mod--invalidate");
      bcl.u.addClass(form, "errorValidationForm");
    } else if (!form.classList.contains("errorValidationForm")) {
      bcl.u.removeClass(input, "mod--invalidate");
      bcl.u.removeClass(form, "errorValidationForm");
    }
  },

  submitPersonalForm: function (event) {
    event.preventDefault();
    const form = event.target;
    const invalidInput = form.querySelector(bcl.c.mybEditProfile.props.invalidInput);
    if (!invalidInput) {
      const inputName = form.querySelector(bcl.c.mybEditProfile.personalElements.name);
      if (inputName) {
        bcl.c.mybEditProfile.validateInput(inputName, form);
      }
      bcl.c.mybEditProfile.setValuekey("givenName", bcl.c.mybEditProfile.props.inputs.name?.value);

      const inputSurname = form.querySelector(bcl.c.mybEditProfile.personalElements.surname);
      if (inputSurname) {
        bcl.c.mybEditProfile.validateInput(inputSurname, form);
      }
      bcl.c.mybEditProfile.setValuekey("surname", bcl.c.mybEditProfile.props.inputs.surname?.value);

      bcl.c.mybEditProfile.setValuekey("birthdayDate", bcl.c.mybEditProfile.props.inputs.birthday?.value);

      bcl.c.mybEditProfile.setValuekey("phoneNumber", bcl.c.mybEditProfile.props.inputs.phone?.value);
      const phone = bcl.c.mybEditProfile?.props?.inputs?.phonePrefix?.querySelector(bcl.c.mybEditProfile?.personalElements?.phone);
      if (phone && window?.intlTelInputGlobals?.getInstance(phone)?.getSelectedCountryData()?.dialCode) {
        bcl.c.mybEditProfile.setValuekey("phoneCountryPrefix", window.intlTelInputGlobals.getInstance(phone).getSelectedCountryData().dialCode);
      }

      const inputAddress = form.querySelector(bcl.c.mybEditProfile.personalElements.address);
      if (inputAddress) {
        bcl.c.mybEditProfile.validateInput(inputAddress, form);
      }
      bcl.c.mybEditProfile.setValuekey("address", bcl.c.mybEditProfile.props.inputs.address?.value);

      const inputPostalCode = form.querySelector(bcl.c.mybEditProfile.personalElements.postalCode);
      if (inputPostalCode) {
        bcl.c.mybEditProfile.validateInput(inputPostalCode, form);
      }
      bcl.c.mybEditProfile.setValuekey("postalCode", bcl.c.mybEditProfile.props.inputs.postalCode?.value);

      const inputProvince = form.querySelector(bcl.c.mybEditProfile.personalElements.province);
      if (inputProvince) {
        bcl.c.mybEditProfile.validateInput(inputProvince, form);
      }
      bcl.c.mybEditProfile.setValuekey("state", bcl.c.mybEditProfile.props.inputs.province?.value);

      const inputCity = form.querySelector(bcl.c.mybEditProfile.personalElements.city);
      if (inputCity) {
        bcl.c.mybEditProfile.validateInput(inputCity, form);
      }
      bcl.c.mybEditProfile.setValuekey("city", bcl.c.mybEditProfile.props.inputs.city?.value);

      bcl.c.mybEditProfile.setValuekey("country", bcl.c.mybEditProfile.props.inputs.country?.value.toLowerCase());

      if (!form.classList.contains("errorValidationForm")) {
        bcl.c.mybEditProfile.updateApiData(form);
      } else {
        bcl.c.mybEditProfile.errorSubmit(event, form);
      }
    }
  },

  setValuekey: function (key, value) {
    if (!key || !value) {
      return;
    }
    bcl.c.mybEditProfile.props.info[key] = value;
  },

  updateApiData: function (form) {
    bcl.u.addBhgLoading(null, true);
    bcl.ajax.postRequest({
      data: bcl.c.mybEditProfile.props.info,
      url: bcl.c.mybEditProfile.props.endpointPost,
      callback: (e) => {
        bcl.u.removeBhgLoading();
        bcl.c.mybEditProfile.successSubmit(e, form);
      },
      errorCallback: (e) => {
        bcl.u.removeBhgLoading();
        bcl.c.mybEditProfile.errorSubmit(e, form);
      },
    });
  },

  getApiData: function (callback) {
    bcl.ajax.getRequest(null, bcl.c.mybEditProfile.props.endpointGet, callback);
  },

  setApiData: function (request) {
    const data = JSON.parse(request.response);
    bcl.c.mybEditProfile.props.data = data;
    if (data.givenName) {
      bcl.c.mybEditProfile.props.inputs.name.value = data.givenName;
    }
    if (data.surname) {
      bcl.c.mybEditProfile.props.inputs.surname.value = data.surname;
    }
    const birthday = bcl.c.mybEditProfile.props.inputs.form.querySelectorAll(bcl.c.mybEditProfile.personalElements.birthday);
    bcl.c.mybEditProfile.props.inputs.birthday = birthday[0];
    if (data.birthdayDate && data.birthdayDate !== "1900-01-01") {
      birthday[0].value = data.birthdayDate;
      if (birthday[1]) {
        birthday[1].value = data.birthdayDate.split("-").reverse().join("/");
      }
    }
    if (data.phoneNumber && data.phoneNumber !== "000000000") {
      bcl.c.mybEditProfile.props.inputs.phone.value = data.phoneNumber;
    }

    setTimeout(bcl.c.mybEditProfile.selectedFlag, 300);

    if (data.address) {
      bcl.c.mybEditProfile.props.inputs.address.value = data.address;
    }
    if (data.postalCode) {
      bcl.c.mybEditProfile.props.inputs.postalCode.value = data.postalCode;
    }
    if (data.state) {
      bcl.c.mybEditProfile.props.inputs.province.value = data.state;
    }
    if (data.city) {
      bcl.c.mybEditProfile.props.inputs.city.value = data.city;
    }
    if (data.country) {
      bcl.c.mybEditProfile.props.inputs.country.value = data.country.toUpperCase();
    }
  },

  selectedFlag: function () {
    const prefix = bcl.c.mybEditProfile.props.inputs.phonePrefix.querySelector(`.iti__country.iti__standard[data-dial-code="${bcl.c.mybEditProfile.props.data.phoneCountryPrefix}"]`);
    const selected = bcl.c.mybEditProfile.props.inputs.phonePrefix.querySelector(".iti__selected-flag");
    if (prefix && selected) {
      const newFlag = prefix ? prefix.querySelector(".iti__flag") : null;
      const newName = prefix ? prefix.querySelector(".iti__country-name") : null;
      const dialCode = prefix ? prefix.querySelector(".iti__dial-code") : null;
      const selectedFlag = selected ? selected.firstElementChild : null;
      if (newFlag && newName && dialCode && selectedFlag) {
        selected.title = newName.innerHTML + ": " + dialCode;
        selectedFlag.classList = newFlag.classList;
      }
    }
  },

  setInputs: function (form) {
    if (form) {
      bcl.c.mybEditProfile.props.inputs.form = form;
    }

    const name = form.querySelector(bcl.c.mybEditProfile.personalElements.name);
    if (name) {
      bcl.c.mybEditProfile.props.inputs.name = name;
    }

    const surname = form.querySelector(bcl.c.mybEditProfile.personalElements.surname);
    if (surname) {
      bcl.c.mybEditProfile.props.inputs.surname = surname;
    }

    const birthday = form.querySelector(bcl.c.mybEditProfile.personalElements.birthday);
    if (birthday) {
      bcl.c.mybEditProfile.props.inputs.birthday = birthday;
    }

    const phone = form.querySelector(bcl.c.mybEditProfile.personalElements.phone);
    if (phone) {
      bcl.c.mybEditProfile.props.inputs.phone = phone;
    }

    const phonePrefix = form.querySelector(bcl.c.mybEditProfile.personalElements.phonePrefixParent);
    if (phonePrefix) {
      bcl.c.mybEditProfile.props.inputs.phonePrefix = phonePrefix;
    }

    const address = form.querySelector(bcl.c.mybEditProfile.personalElements.address);
    if (address) {
      bcl.c.mybEditProfile.props.inputs.address = address;
    }

    const postalCode = form.querySelector(bcl.c.mybEditProfile.personalElements.postalCode);
    if (postalCode) {
      bcl.c.mybEditProfile.props.inputs.postalCode = postalCode;
    }

    const province = form.querySelector(bcl.c.mybEditProfile.personalElements.province);
    if (province) {
      bcl.c.mybEditProfile.props.inputs.province = province;
    }

    const city = form.querySelector(bcl.c.mybEditProfile.personalElements.city);
    if (city) {
      bcl.c.mybEditProfile.props.inputs.city = city;
    }

    const country = form.querySelector(bcl.c.mybEditProfile.personalElements.country);
    if (country) {
      bcl.c.mybEditProfile.props.inputs.country = country;
    }
    bcl.c.mybEditProfile.getApiData(bcl.c.mybEditProfile.setApiData);
  },

  successSubmit: function (e, form) {
    const successModal = form.querySelector(bcl.c.mybEditProfile.modals.successModal);
    const closeModal = successModal.querySelector(".js-close-modal");
    closeModal.addEventListener("click", () => {
      successModal.classList.remove(bcl.c.mybEditProfile.modals.activeClass);
    });
    successModal.classList.add(bcl.c.mybEditProfile.modals.activeClass);
  },

  errorSubmit: function (e, form) {
    const errorModal = form.querySelector(bcl.c.mybEditProfile.modals.errorModal);
    const closeModal = errorModal.querySelector(".js-close-modal");
    closeModal.addEventListener("click", () => {
      errorModal.classList.remove(bcl.c.mybEditProfile.modals.activeClass);
    });
    errorModal.classList.add(bcl.c.mybEditProfile.modals.activeClass);
  },
};
