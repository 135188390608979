bcl.c.tabs = {
  init: function () {
    document.querySelectorAll(".cmp-tab-JS").forEach((component) => {
      const selector = component.dataset.selectedTab;
      if (selector) {
        component.querySelectorAll(".cmp-anchor-id-JS").forEach((anchorElement) => {
          if (anchorElement.dataset.anchorId === selector) {
            const closestTabPanel = bcl.u.closest(anchorElement, ".cmp-tab-panel-JS");
            if (closestTabPanel) {
              const selectedTab = component.querySelector(`li#tab-${closestTabPanel.dataset.title}`);
              if (selectedTab) {
                selectedTab.click();
                return; // Exit the loop once the selected tab is clicked
              }
            }
          }
        });
      }
    });
  },
};

bcl.u.docReady(bcl.c.tabs.init);
