// Vanilla Cookies - a dead simple yet full-featured JavaScript cookie library
// derived from http://stackoverflow.com/a/19189846

/*********************************************************
 Gets the value of a cookie.
 **********************************************************/

bcl.cookie = {
  get: function (sName) {
    const oCrumbles = document.cookie.split(";");
    for (let i = 0; i < oCrumbles.length; i++) {
      const oPair = oCrumbles[i].split("="),
        sKey = decodeURIComponent(oPair[0].trim()),
        sValue = oPair.length > 1 ? oPair[1] : "";

      if (sKey == sName) {
        return decodeURIComponent(sValue);
      }
    }

    return "";
  },

  /*****************************************************************
     Sets the value of a cookie. Expiration date and path are optional.
     ******************************************************************/
  set: function (sName, sValue, options) {
    let sCookie = encodeURIComponent(sName) + "=" + encodeURIComponent(sValue);
    // Shorthand: options === expires date
    if (options && options instanceof Date) {
      options = {
        expires: options,
      };
    }

    // Longhand: options object
    if (options && typeof options == "object") {
      if (options.expires) {
        sCookie += "; expires=" + options.expires.toGMTString();
      }
      if (options.path) {
        sCookie += "; path=" + options.path.toString();
      }
      if (options.domain) {
        sCookie += "; domain=" + options.domain.toString();
      }
      if (options.secure) {
        sCookie += "; secure";
      }
    }

    document.cookie = sCookie;
  },

  /*********************************************************
     Clears and immediately expires a cookie.
     **********************************************************/
  remove: function (sName, path) {
    if (path) {
      path = "; path=" + path;
    } else {
      path = "";
    }

    document.cookie = sName + "=" + path + ";expires=Thu, 01 Jan 1970 00:00:00 GMT";
  },

  reopenCookieConfig: function () {
    bcl.u.utag.push({
      tealium_event: "reopen gdpr modal",
      event_category: "gdpr modal",
      event_action: "reopen gdpr modal - cookies page",
      event_label: utag_data.pagecategorization,
    });
  },
};
