bcl.s.tooltip = {
  props: {
    containerTooltipSelector: "tooltip-JS",
    tooltipDisabledSelector: "tooltip-disabled-JS",
    tooltipTempEnabledSelector: "tooltip-tempEnabled-JS",
    caretSelector: "caret-JS",
    classNoClose: "no-close-JS",
    classClose: ".close-JS",
    classActive: "mod--active",
    classDeactive: "mod--hide",
    classShowOnload: "mod--showOnload",
    classShowOnloaded: "mod--showOnloaded",
    classTooltipFullWidth: "mod--tooltip-full-width",
    tooltipAttr: "data-tooltip",
    popoverClassName: "c-popover__content-block",
    tooltipFullJs: "tooltip-full-JS",
    tooltipIconSelector: "bhg-tooltip--icon",
    $containerActive: null,
    $lastTooltip: null,
    $containerTooltip: null,
    $caret: null,
  },

  init: function (baseDom) {
    if (bcl.editMode || bcl.u.browser.IE()) {
      return;
    }

    const baseFind = baseDom ? baseDom : document;

    const containerTabs = baseFind.getElementsByClassName(bcl.s.tooltip.props.containerTooltipSelector);
    bcl.u.forEach(containerTabs, bcl.s.tooltip.initContainer);

    if (containerTabs.length > 0) {
      window.addEventListener("scroll", bcl.s.tooltip.resize, false);
    }
  },

  initContainer: function ($containerTooltip) {
    const $popover = bcl.s.tooltip.searchPopover($containerTooltip.dataset.tooltip);

    if (!$popover) {
      return;
    }

    if ($popover.dataset.register !== "true") {
      bcl.s.tooltip.initPopover($containerTooltip, $popover);
    }

    if ($containerTooltip.dataset.register !== "true" && !$containerTooltip.dataset.noevent) {
      $containerTooltip.dataset.register = "true";

      $containerTooltip.addEventListener($containerTooltip.dataset.event || "click", bcl.s.tooltip.startEvent);

      if ($containerTooltip.dataset.event === "mouseover") {
        $containerTooltip.addEventListener("mouseout", bcl.s.tooltip.startEvent);
      }

      if ($containerTooltip.dataset.event === "mouseenter") {
        $containerTooltip.addEventListener("mouseleave", bcl.s.tooltip.startEvent);
      }

      $containerTooltip.$containerTooltip = $containerTooltip;
      $containerTooltip.$popover = $popover;

      if ($popover.classList.contains(bcl.s.tooltip.props.classShowOnload) && !bcl.u.mobile.isMobile()) {
        bcl.s.tooltip.click($containerTooltip, $popover);
      }
    }
  },
  initPopover: function ($container, $popover) {
    document.body.appendChild($popover);

    const $close = $popover.querySelector(bcl.s.tooltip.props.classClose);

    if ($close) {
      $close.addEventListener("click", function () {
        bcl.s.tooltip.close($container, $popover);
      });
    }

    $popover.dataset.register = "true";
  },
  startEvent: function (e) {
    bcl.s.tooltip.props.clickInContainer = true;
    if (e.currentTarget.$containerTooltip && e.currentTarget.$popover) {
      bcl.s.tooltip.click(e.currentTarget.$containerTooltip, e.currentTarget.$popover);
    }
  },

  activeTooltip: function (className) {
    const $containerTooltip = document.querySelector("." + className);

    if (!$containerTooltip) {
      return;
    }

    const $popover = bcl.s.tooltip.searchPopover($containerTooltip.dataset.tooltip);
    bcl.s.tooltip.click($containerTooltip, $popover);
  },

  searchPopover: function (className) {
    const $popover = document.getElementsByClassName(className);

    if (!$popover || !$popover[0]) {
      return;
    }

    return $popover[0];
  },

  click: function ($container, $popover) {
    if (bcl.editMode || bcl.u.browser.IE() || ($container.classList.contains(bcl.s.tooltip.props.tooltipDisabledSelector) && !$container.classList.contains(bcl.s.tooltip.props.tooltipTempEnabledSelector))) {
      return;
    }

    if ($container.dataset.tooltipMessage) {
      const messageContainer = $popover.getElementsByClassName("message-JS");
      const messageList = $popover.getElementsByClassName("c_popover__messages");
      if (messageContainer) {
        let content = "";
        if (messageList && messageList[0]) {
          const $message = messageList[0].getElementsByClassName($container.dataset.tooltipMessage);
          content = $message && $message.length > 0 ? $message[0].innerHTML : "";
        }

        if ($container.dataset.tooltipMessage === "hotelClosingMinstay") {
          content += " " + bcl.c.fastbooking.calendar.props.options.minNights;
        }

        messageContainer[0].innerHTML = content;
        if ($container.dataset.tooltipMessage === "hotelRelatedPrice") {
          messageContainer[0].getElementsByClassName("date-hotel-opening-JS")[0].addEventListener("click", function () {
            bcl.c.fastbooking.calendar.goToDate(bcl.c.fastbooking.calendar.props.lastDateHotelopening);
            bcl.c.fastbooking.calendar.deleteDates();
            bcl.c.fastbooking.calendar.showOrHideRelateds();
            bcl.s.tabs.props.clickInContainer = true;
          });
        }
      }
    }

    bcl.s.tooltip.updateData($container, $popover);

    if (!bcl.s.tooltip.processOpen($container, $popover) && !bcl.u.containsClass($popover, bcl.s.tooltip.props.classNoClose)) {
      bcl.s.tooltip.close($container, $popover);
      return;
    }

    bcl.s.tooltip.props.$containerActive = $container;
    bcl.s.tooltip.props.$lastTooltip = $popover;
    bcl.s.tooltip.props.fixedActive = null;
  },

  update: function ($container, $popover) {
    bcl.s.tooltip.addStyle($container, $popover);
  },

  updateData: function ($container, $popover) {
    bcl.s.tooltip.props.$caret = $popover.querySelector("." + bcl.s.tooltip.props.caretSelector);
  },

  processOpen: function ($container, $popover) {
    if (bcl.s.tooltip.props.$containerActive == null) {
      if (!$container.dataset.noclosewindow) {
        window.addEventListener("click", bcl.s.tooltip.clickWindow);
      }
      return bcl.s.tooltip.open($container, $popover);
    }

    if ($container != bcl.s.tooltip.props.$containerActive) {
      if ($popover.classList.contains(bcl.s.tooltip.props.classShowOnloaded)) {
        bcl.u.removeClass($popover, bcl.s.tooltip.props.classShowOnloaded);
        return true;
      } else {
        return bcl.s.tooltip.openWithContainerDistinct($container, $popover);
      }
    }

    return false;
  },

  clickWindow: function () {
    if (bcl.s.tooltip.props.clickInContainer) {
      bcl.s.tooltip.props.clickInContainer = false;
      return;
    }

    bcl.s.tooltip.closeActive();
  },

  closeActive: function (avoidRemoveWindowsListener) {
    if (!bcl.s.tooltip.props.$containerActive || !bcl.s.tooltip.props.$lastTooltip) {
      return;
    }

    bcl.s.tooltip.close(bcl.s.tooltip.props.$containerActive, bcl.s.tooltip.props.$lastTooltip, avoidRemoveWindowsListener);
  },

  openWithContainerDistinct: function ($container, $popover) {
    bcl.u.removeClass(bcl.s.tooltip.props.$containerActive, bcl.s.tooltip.props.classActive);

    return bcl.s.tooltip.open($container, $popover);
  },

  open: function ($container, $popover) {
    if ($popover.classList.contains(bcl.s.tooltip.props.classShowOnload)) {
      bcl.u.removeClass($popover, bcl.s.tooltip.props.classShowOnload);
      bcl.u.addClass($popover, bcl.s.tooltip.props.classShowOnloaded);
    } else {
      bcl.s.tooltip.closeActive(true);
    }

    bcl.u.removeClass($popover, bcl.s.tooltip.props.classDeactive);
    bcl.s.tooltip.changeClass($container, $popover, "addClass");
    bcl.s.tooltip.addStyle($container, $popover);

    return true;
  },

  close: function ($container, $popover, avoidRemoveWindowsListener) {
    !avoidRemoveWindowsListener && window.removeEventListener("click", bcl.s.tooltip.clickWindow);

    bcl.s.tooltip.props.$containerActive = null;
    bcl.s.tooltip.props.$lastTooltip = null;
    bcl.s.tooltip.props.fixedActive = null;

    bcl.u.addClass($popover, bcl.s.tooltip.props.classDeactive);
    bcl.s.tooltip.changeClass($container, $popover, "removeClass");
    bcl.u.removeClass(bcl.s.tooltip.props.$lastTooltip, "mod--fixed");
    bcl.u.removeClass(bcl.s.tooltip.props.$lastTooltip, "mod--stop-animation");

    bcl.s.tabs.props.clickInContainer = true;

    $popover.style.left = null;
    bcl.s.tooltip.props.$caret.style.left = null;
  },

  changeClass: function ($container, $popover, method) {
    bcl.u[method]($popover, bcl.s.tooltip.props.classActive);
    bcl.u[method]($container, bcl.s.tooltip.props.classActive);
  },

  addStyle: function ($container, $popover) {
    bcl.s.tooltip.addStyleLeft($container, $popover);
    bcl.s.tooltip.addStyleTop($container, $popover);
  },

  addStyleLeft: function ($container, $popover) {
    const prop = "width";
    let propContainerValue = $container.dataset[prop];

    if (bcl.u.mobile.isMobile() && $container.getBoundingClientRect().left + $container.offsetWidth < $popover.offsetWidth) {
      propContainerValue = "left";
    }

    if (propContainerValue == "center") {
      bcl.s.tooltip.processCenter($container, $popover);
    } else {
      bcl.s.tooltip.processLeft($container, $popover);
    }
  },

  addStyleTop: function ($container, $popover) {
    $popover.style.top = bcl.s.tooltip.calcPopoverTop($container, $popover) + "px";
    if (bcl.s.tooltip.props.$caret) {
      bcl.s.tooltip.props.$caret.style.top = bcl.s.tooltip.calcCaretTop($container, $popover) + "px";
    }
  },
  processCenter: function ($container, $popover) {
    if (bcl.u.mobile.isMobile() && bcl.u.containsClass($container, bcl.s.tooltip.props.tooltipFullJs)) {
      bcl.u.addClass($popover, bcl.s.tooltip.props.classTooltipFullWidth);
    } else if (bcl.u.getOffset($container).left + $popover.offsetWidth >= window.innerWidth) {
      bcl.u.removeClass($popover, bcl.s.tooltip.props.classTooltipFullWidth);
    }
    const popoverDefaultLeft = bcl.u.getOffset($container).left - ($popover.offsetWidth - $container.offsetWidth) / 2;
    $popover.style.left = bcl.s.tooltip.calcPopoverLeft($container, $popover, popoverDefaultLeft) + "px";
    bcl.s.tooltip.props.$caret.style.left = bcl.s.tooltip.calcCaretLeft($container, $popover) + "px";
  },
  processLeft: function ($container, $popover) {
    if (bcl.u.mobile.isMobile() && bcl.u.containsClass($container, bcl.s.tooltip.props.tooltipFullJs) && bcl.u.containsClass($popover, bcl.s.tooltip.props.classTooltipFullWidth)) {
      bcl.u.removeClass($popover, bcl.s.tooltip.props.classTooltipFullWidth);
    }
    const popoverDefaultLeft = bcl.u.getOffset($container).left - bcl.s.tooltip.props.$caret.offsetWidth / 2;
    $popover.style.left = bcl.s.tooltip.calcPopoverLeft($container, $popover, popoverDefaultLeft) + "px";
    bcl.s.tooltip.props.$caret.style.left = bcl.s.tooltip.calcCaretLeft($container, $popover) + "px";
  },
  calcPopoverLeft: function ($container, $popover, popoverDefaultLeft) {
    if ($popover.classList.contains(bcl.s.tooltip.props.classTooltipFullWidth)) {
      return 0;
    }
    const containerPadding = bcl.s.tooltip.getPopoverContainer($container),
      popoverMaxLeft = containerPadding,
      popoverMaxRight = window.innerWidth - $popover.offsetWidth,
      popoverLeft = popoverDefaultLeft < popoverMaxLeft ? popoverMaxLeft : popoverDefaultLeft > popoverMaxRight ? popoverMaxRight : popoverDefaultLeft;
    return popoverLeft;
  },
  calcCaretLeft: function ($container, $popover) {
    return bcl.u.getOffset($container).left - bcl.u.getOffset($popover).left + $container.offsetWidth / 2 - bcl.s.tooltip.props.$caret.offsetWidth / 2;
  },
  calcPopoverTop: function ($container, $popover) {
    let popoverTop = $container.offsetHeight;
    const caretOffsetHeight = bcl.s.tooltip.props.$caret ? bcl.s.tooltip.props.$caret.offsetHeight : 0;

    if (!bcl.s.tooltip.props.fixedActive) {
      popoverTop = bcl.u.getOffset($container).top + $container.offsetHeight + caretOffsetHeight / 2;
      const placement = $popover.dataset.tooltipPlacement;
      if ("top" == placement) {
        popoverTop = bcl.u.getOffset($container).top - $popover.offsetHeight - caretOffsetHeight / 2;
      }
    }

    return popoverTop;
  },
  calcCaretTop: function ($container, $popover) {
    if (bcl.u.getOffset($container).top > bcl.u.getOffset($popover).top) {
      return $popover.offsetHeight - bcl.s.tooltip.props.$caret.offsetHeight / 2;
    }
    return -bcl.s.tooltip.props.$caret.offsetHeight / 2;
  },
  getPopoverContainer: function ($container) {
    const $popoverContainer = $container.closest("." + bcl.s.tooltip.props.popoverClassName);
    return $popoverContainer ? parseInt(window.getComputedStyle($popoverContainer).padding) : 0;
  },
  isSticky: function (sticky, obj) {
    if (bcl.editMode) {
      return;
    }

    if (!bcl.s.tooltip.props.$lastTooltip || !bcl.s.tooltip.props.$lastTooltip.dataset[obj.attr || ""]) {
      return;
    }

    if (sticky) {
      bcl.s.tooltip.props.fixedActive = true;
      bcl.u.addClass(bcl.s.tooltip.props.$lastTooltip, "mod--fixed");
      bcl.u.addClass(bcl.s.tooltip.props.$lastTooltip, "mod--stop-animation");
      bcl.s.tooltip.update(bcl.s.tooltip.props.$containerActive, bcl.s.tooltip.props.$lastTooltip);
    } else {
      bcl.s.tooltip.props.fixedActive = false;
      bcl.s.tooltip.update(bcl.s.tooltip.props.$containerActive, bcl.s.tooltip.props.$lastTooltip);
      bcl.u.removeClass(bcl.s.tooltip.props.$lastTooltip, "mod--fixed");
    }
  },

  resize: function () {
    if (bcl.editMode || bcl.u.browser.IE()) {
      return;
    }

    if (bcl.s.tooltip.props.$containerActive && bcl.s.tooltip.props.$lastTooltip) {
      bcl.s.tooltip.update(bcl.s.tooltip.props.$containerActive, bcl.s.tooltip.props.$lastTooltip);
    }
  },
};

bcl.u.docReady(bcl.s.tooltip.init);
bcl.u.resize(bcl.s.tooltip.resize);
