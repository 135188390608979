bcl.s.tabsComponent = {
  props: {
    cssSelector: "empty-option-tabs-JS",
    active: "empty-div-tabs-JS",
    activeTab: "cmp-tabs__tab--active",
    activePanel: "cmp-tabs__tabpanel--active",
    cmpTabs: "cmp-tabs",
    tabButton: "cmp-tabs__tab",
  },

  init: function (baseDom) {
    if (bcl.editMode) {
      return;
    }

    const baseFind = baseDom ? baseDom : document;

    const $items = baseFind.getElementsByClassName(bcl.s.tabsComponent.props.cssSelector);

    bcl.u.forEach($items, bcl.s.tabsComponent.initTab);

    const $itemsTabs = baseFind.getElementsByClassName(bcl.s.tabsComponent.props.cmpTabs);

    bcl.u.forEach($itemsTabs, bcl.s.tabsComponent.initCmpTab);
  },

  initTab: function ($item) {
    const $nodes = $item.getElementsByClassName(bcl.s.tabsComponent.props.active);

    bcl.u.forEach($nodes, function ($node) {
      bcl.u.removeClass($node, bcl.s.tabsComponent.props.active);

      if (bcl.u.containsClass($node, "cmp-tabs__tab")) {
        bcl.u.removeClass($node, bcl.s.tabsComponent.props.activeTab);
        $node.setAttribute("aria-selected", false);
        $node.setAttribute("tabindex", -1);
      } else {
        bcl.u.removeClass($node, bcl.s.tabsComponent.props.activePanel);
        $node.setAttribute("aria-hidden", "true");
      }
    });
  },

  initCmpTab: function ($item) {
    const $nodes = $item.getElementsByClassName(bcl.s.tabsComponent.props.tabButton);

    bcl.u.forEach($nodes, function ($node) {
      $node.addEventListener("click", function (e) {
        bcl.s.tabsComponent.findAndTogglePopCom();
        const $tabContainer = document.querySelector(`[data-title="${e.target.id.replace("tab-", "")}"]`);
        if ($tabContainer) {
          const interval = setInterval(function () {
            if ($tabContainer.offsetParent) {
              clearInterval(interval);
              window.dispatchEvent(new CustomEvent("cmp-image-global-redraw", { detail: $tabContainer }));
            }
          }, 100);
        }
      });
    });
  },

  findAndTogglePopCom: function () {
    const comparator = document.querySelector(".offers-comparator"),
      popCompare = document.querySelector(".tooltip-JS-compare");

    comparator && popCompare && bcl.s.tabsComponent.togglePopComparator(comparator, popCompare);
  },

  togglePopComparator: function (comparator, popComparator) {
    const panelWComparator = comparator.closest(".cmp-tabs__tabpanel"),
      popContainer = document.querySelector("[data-tooltip=tooltip-JS-compare]");

    if (!bcl.u.containsClass(panelWComparator, "cmp-tabs__tabpanel--active")) {
      bcl.u.containsClass(popComparator, "mod--active") && bcl.u.removeClass(popComparator, "mod--active");
    } else {
      bcl.s.tabsComponent.updatePopComp(popContainer, popComparator);
      !bcl.u.containsClass(popComparator, "mod--active") && bcl.u.addClass(popComparator, "mod--active");
      bcl.s.tooltip.resize();
    }
  },

  updatePopComp: function (popContainer, popComparator) {
    bcl.s.tooltip.props.$containerActive = popContainer;
    bcl.s.tooltip.props.$lastTooltip = popComparator;

    bcl.s.tooltip.updateData(popContainer, popComparator);
    bcl.s.tooltip.update(popContainer, popComparator);
  },
};

bcl.u.docReady(bcl.s.tabsComponent.init);
