bcl.c.globalEvent = {
  props: {
    goBackSelector: "c-header-detail__header-back",
    goBackSessionValue: "backValue",
    goBackIndexName: "backIndex",
    pageAttribute: "data-master-page-id",
    isTarget: null,
    cHeaderSwitchLogoJS: "c-header__switch-logo-JS",
  },

  init: function () {
    bcl.c.globalEvent.addGlobalListener();

    //Global target function
    bcl.c.globalEvent.addGlobalTargetListener();
  },

  addGlobalListener: function (baseDom) {
    const $container = baseDom ? baseDom : document;
    $container.addEventListener("click", bcl.c.globalEvent.checkAnchor);
  },

  addGlobalTargetListener: function (baseDom) {
    const $container = baseDom ? baseDom : document;
    $container.addEventListener("load-target", bcl.c.globalEvent.checkTargetComponent);
  },

  checkTargetComponent: function (event) {
    bcl.c.globalEvent.props.isTarget = true;
    const $searchComponentTarget = {
      assetVideo: { selector: ".c-video", initReference: "isAssetVideo" },
      comparator: { selector: ".hotels-comparator, .offers-comparator, .meetings-comparator, .collections-comparator", related: "initAccordion", initReference: "isComparator" },
      heroSlider: { selector: ".c-heroslider", initReference: "isHeroSlider" },
      couponOffer: { selector: ".coupon", initReference: "isCouponOffer" },
      campingCard: { selector: ".c-card", initReference: "isCampaingCard" },
      hotelCard: { selector: ".c-card-hotel", initReference: "isCardHotel" },
      hotelCardLayout: { selector: ".mod--card-hotel-target", initReference: "isCardHotelLayout" },
    };
    for (let i = 0; i < Object.keys($searchComponentTarget).length; i++) {
      const $currentObjectSelector = $searchComponentTarget[Object.keys($searchComponentTarget)[i]];
      const $component = bcl.c.globalEvent.searchComponent(event.detail, $currentObjectSelector),
        funInit = {
          isAssetVideo: () => {
            if (bcl.c.assetVideo && bcl.c.assetVideo.initVideos) {
              return bcl.c.assetVideo.initVideos();
            }
          },
          isComparator: () => {
            if (bcl.c.distributor && bcl.c.distributor.beforeInit) {
              return bcl.c.distributor.beforeInit();
            }
          },
          initAccordion: () => {
            if (bcl.c.accordion && bcl.c.accordion.init) {
              return bcl.c.accordion.init();
            }
          },
          isHeroSlider: () => {
            if (bcl.c.heroslider && bcl.c.heroslider.init) {
              return bcl.c.heroslider.init();
            }
          },
          isCouponOffer: () => {
            if (bcl.c.coupon && bcl.c.coupon.init) {
              return bcl.c.coupon.init();
            }
          },
          isCampaingCard: () => {
            if (bcl.c.card && bcl.c.card.init) {
              return bcl.c.card.init();
            }
          },
          isCardHotel: () => {
            if (bcl.c.fastbooking && bcl.c.fastbooking.initBookings) {
              return bcl.c.fastbooking.initBookings();
            }
          },
          isCardHotelLayout: () => {
            if (bcl.c.cardHotelList && bcl.c.cardHotelList.init) {
              return bcl.c.cardHotelList.init();
            }
          },
        };
      $component[0] && funInit[$component[1]]();
      $component[0] && $component[2] && funInit[$component[2]]();
    }
  },

  searchComponent: function ($container, objectSelector) {
    let $componentFound = [null, "noComparator", "noAccordion"];
    const $componentInDOM = $container ? $container.querySelector(objectSelector.selector) : null;

    if ($componentInDOM) {
      $componentFound = [$componentInDOM, objectSelector.initReference, objectSelector.related];
    }

    return $componentFound;
  },

  checkAnchor: function (event) {
    let page;
    const $node = event.target.tagName.toLowerCase() === "a" ? event.target : bcl.u.closest(event.target, "a");
    const pageAttribute = document.documentElement.getAttribute(bcl.c.globalEvent.props.pageAttribute);
    if (pageAttribute) {
      page = pageAttribute.toLowerCase();
    }

    if (page && page === "home") {
      bcl.c.globalEvent.deleteSession();
    }

    if ($node && $node.href && page !== "home") {
      bcl.c.globalEvent.checkBackValue($node.href, event);
    }

    bcl.c.globalEvent.checkIfIsClickInLogo($node);
  },

  checkIfIsClickInLogo: function ($node) {
    if ($node && bcl.u.containsClass($node, bcl.c.globalEvent.props.cHeaderSwitchLogoJS)) {
      bcl.c.fastbooking.calendar.removeCookie(bcl.c.fastbooking.rooms.props.cookieName);
      bcl.c.fastbooking.calendar.cleanDatesFastBooking();
    }
  },

  checkBackValue: function (href, event) {
    bcl.c.fastbooking?.isCampaignGroup?.();

    if (sessionStorage.backValue) {
      if ((sessionStorage.backValue === href && href != window.location.href) || (sessionStorage.isCampaginGroup === sessionStorage.backValue && bcl.u.containsClass(event.target, ":not(accordion-toggle__title-standard)"))) {
        event.preventDefault();
        event.stopPropagation();
        window.history.go(parseInt(sessionStorage.backIndex));
        bcl.c.globalEvent.deleteSession();
      } else {
        if (href) {
          const isChildPage = bcl.c.globalEvent.isChildPage(href);
          if (isChildPage) {
            sessionStorage.setItem(bcl.c.globalEvent.props.goBackIndexName, parseInt(sessionStorage.backIndex - 1).toString());
          } else {
            bcl.c.globalEvent.deleteSession();
            bcl.c.globalEvent.createSession();
          }
        }
      }
    } else if (event.target.target != "_blank") {
      bcl.c.globalEvent.createSession();
    }
  },

  isChildPage: function (href) {
    href = bcl.u.includes(href, ".html") ? href.split(".html")[0] : href;
    const currentBackValue = bcl.u.includes(sessionStorage.backValue, ".html") ? sessionStorage.backValue.split(".html")[0] : sessionStorage.backValue;

    const hrefLength = href.split(window.location.origin)[1].split("/").length;
    const currentBackValueLength = currentBackValue.split(window.location.origin)[1].split("/").length;

    return href.startsWith(currentBackValue) && hrefLength > currentBackValueLength ? true : false;
  },

  deleteSession: function () {
    sessionStorage.removeItem(bcl.c.globalEvent.props.goBackSessionValue);
    sessionStorage.removeItem(bcl.c.globalEvent.props.goBackIndexName);
  },

  createSession: function () {
    sessionStorage.setItem(bcl.c.globalEvent.props.goBackSessionValue, window.location.href);
    sessionStorage.setItem(bcl.c.globalEvent.props.goBackIndexName, "-1");
  },
};

bcl.preInit.push(bcl.c.globalEvent.init);
