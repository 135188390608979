bcl.s.rteTooltip = {
  props: {
    cssClass: "bhg-tooltip",
    hidden: "--hidden",
    icon: "--icon",
    text: "--text",
    classActive: "mod--active",
    timeout: [],
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document,
      elements = baseFind.getElementsByClassName(bcl.s.rteTooltip.props.cssClass + bcl.s.rteTooltip.props.hidden);

    for (let i = elements.length - 1; i >= 0; i--) {
      bcl.s.rteTooltip.process(elements[i]);
    }
  },

  process: function ($element) {
    const $parent = bcl.s.rteTooltip.processNodes($element);
    bcl.s.rteTooltip.processEvents($element, $parent);
  },

  processNodes: function ($element) {
    bcl.u.removeClass($element, bcl.s.rteTooltip.props.cssClass + bcl.s.rteTooltip.props.hidden);
    bcl.u.addClass($element, bcl.s.rteTooltip.props.cssClass + bcl.s.rteTooltip.props.text);

    const $parent = document.createElement("span");
    bcl.u.addClass($parent, bcl.s.rteTooltip.props.cssClass);
    bcl.u.wrap($element, $parent);

    const $icon = document.createElement("span");
    bcl.u.addClass($icon, bcl.s.rteTooltip.props.cssClass + bcl.s.rteTooltip.props.icon);
    $parent.setAttribute("data-coloricon", $element.dataset.coloricon);
    $parent.setAttribute("data-color", $element.dataset.color);
    $element.removeAttribute("data-coloricon");
    $element.removeAttribute("data-color");
    $parent.insertBefore($icon, $element);

    return $parent;
  },

  processEvents: function ($element, $parent) {
    $parent.addEventListener("click", function () {
      bcl.s.rteTooltip.position($element, $parent);
      bcl.u.toggleClass($parent, bcl.s.rteTooltip.props.classActive);
    });

    $parent.addEventListener("mouseout", function () {
      const timeout = setTimeout(function () {
        bcl.u.removeClass($parent, bcl.s.rteTooltip.props.classActive);
      }, 300);

      bcl.s.rteTooltip.props.timeout.push(timeout);
    });

    $element.addEventListener("mouseover", function () {
      bcl.s.rteTooltip.clearEvent();
      bcl.u.addClass($parent, bcl.s.rteTooltip.props.classActive);
    });

    $element.addEventListener("mouseout", function () {
      bcl.u.removeClass($parent, bcl.s.rteTooltip.props.classActive);
    });
  },

  position: function ($element, $parent) {
    const left = bcl.u.getOffset($parent).left;

    bcl.u.removeClass($parent, "mod--right");
    bcl.u.removeClass($parent, "mod--left");

    if (left <= 150) {
      bcl.u.addClass($parent, "mod--right");
      return;
    }

    if (left + 300 >= window.innerWidth) {
      bcl.u.addClass($parent, "mod--left");
      return;
    }
  },

  clearEvent: function () {
    for (let i = 0; i < bcl.s.rteTooltip.props.timeout.length; i++) {
      clearTimeout(bcl.s.rteTooltip.props.timeout[i]);
    }

    bcl.s.rteTooltip.props.timeout = [];
  },
};

bcl.u.docReady(bcl.s.rteTooltip.init);
