bcl.s.sticky = {
  props: {
    stickyClass: "isSticky",
    stickySelector: "sticky-JS",
    marginHeader: 0,
    marginFooter: 0,
    items: [],
    itemsStickyTop: [],
    itemsStickyBottom: [],
    $body: null,
  },

  init: function () {
    const components = document.querySelectorAll("." + bcl.s.sticky.props.stickySelector);

    bcl.u.forEach(components, function ($component) {
      bcl.u.addClass($component, bcl.s.sticky.props.stickyClass);

      const attr = $component.dataset.sticky || "top",
        obj = {
          $node: $component,
        };

      if (attr == "bottom") {
        bcl.s.sticky.addStickyBottom(obj);
      } else {
        bcl.s.sticky.addStickyTop(obj);
      }
    });
  },

  resize: function () {
    bcl.s.sticky.props.marginHeader = 0;
    bcl.s.sticky.props.marginFooter = 0;

    for (let i = 0; i < bcl.s.sticky.props.items.length; i++) {
      bcl.s.sticky.process(bcl.s.sticky.props.items[i]);
    }

    bcl.s.sticky.launchSetStyleHero();

    bcl.s.sticky.setMarginInFooter();

    bcl.s.sticky.windowScrollEvent();

    bcl.c?.card?.init();
  },

  add: function (item, isRibbonLanguage) {
    bcl.s.sticky.props.items.push(item);

    if (isRibbonLanguage) {
      bcl.s.sticky.process(item);

      //Check is bot 'Ari' is in the dom
      if (document.querySelector(".inbenta-bot") && !bcl.u.mobile.isMobile()) {
        bcl.s.sticky.moveStickyTop(item);
      }
      bcl.s.sticky.launchSetStyleHero();
    }

    bcl.s.sticky.setMarginInFooter();

    return bcl.s.sticky.props.items.length - 1;
  },

  process: function (item) {
    if (item.inFooter) {
      bcl.s.sticky.calcSpace({
        item: item,
        nameProp: "marginFooter",
        nameAttrStyle: "bottom",
      });
    }
  },

  addStickyTop: function (item) {
    bcl.s.sticky.props.itemsStickyTop.push(item);

    return bcl.s.sticky.props.itemsStickyTop.length - 1;
  },

  addStickyBottom: function (item) {
    bcl.s.sticky.props.itemsStickyBottom.push(item);

    return bcl.s.sticky.props.itemsStickyBottom.length - 1;
  },

  windowScrollEvent: function () {
    if (bcl.editMode) {
      return;
    }

    bcl.s.sticky.props.direction = bcl.s.sticky.props.lastScrollY <= window.scrollY ? 1 : -1;
    bcl.s.sticky.props.lastScrollY = window.scrollY || document.documentElement.scrollTop;

    let item;

    for (let i = 0; i < bcl.s.sticky.props.itemsStickyTop.length; i++) {
      item = bcl.s.sticky.props.itemsStickyTop[i];

      if ((!item.onlyMobile || bcl.u.mobile.isMobile()) && !item.$node.closest(".brh-hero-room-detail")) {
        bcl.s.sticky.stickyTop(item);
      }
    }

    bcl.s.sticky.props.marginFooter = 0;

    for (let i = 0; i < bcl.s.sticky.props.itemsStickyBottom.length; i++) {
      item = bcl.s.sticky.props.itemsStickyBottom[i];

      if (!item.onlyMobile || bcl.u.mobile.isMobile()) {
        bcl.s.sticky.stickyBottom(item);
      }
    }

    bcl.s.sticky.setMarginInFooter();
  },

  stickyTop: function (item) {
    const value = bcl.s.sticky.getScrollTop(item.$node.parentNode);
    bcl.s.sticky.stickyGeneral(item, value + 1, "mod--stick-top");
  },

  stickyBottom: function (item) {
    let value = bcl.s.sticky.getScrollTop(item.$node.parentNode) + item.$node.parentNode.offsetHeight;

    if (item.inverse) {
      value -= window.innerHeight;
    }

    const height = bcl.s.sticky.stickyGeneral(item, value, "mod--stick-bottom");

    if (!item.inverse && height) {
      bcl.s.sticky.props.marginFooter += height;
    }
  },

  getScrollTop: function ($node) {
    const rect = $node.getBoundingClientRect();

    //document.body.scrollTop
    return rect.top;
  },

  stickyGeneral: function (item, value, className, check) {
    const $node = item.$node;

    if (check === undefined) {
      if (!item.inverse) {
        check = value <= 0;
      } else {
        check = value > 0;
      }
    }

    if (check) {
      if (!bcl.u.containsClass($node, className)) {
        $node.parentNode.style.height = $node.parentNode.offsetHeight + "px";
        item.offset = value;
        bcl.u.addClass($node, className);

        if (item.callback) {
          item.callback(true, item.itemCallback || {});
        }
      }

      return $node.offsetHeight;
    } else {
      if (bcl.u.containsClass($node, className)) {
        if (className === "mod--stick-bottom") {
          bcl.u.addClass($node, "mod--hide-booking-sticky");
          bcl.c.header.sticky.props.timeout = setTimeout(function () {
            bcl.u.removeClass($node, "mod--hide-booking-sticky");
            bcl.s.sticky.hideSticky(item, $node, className);
          }, 500);
        } else {
          bcl.s.sticky.hideSticky(item, $node, className);
        }
      }

      return $node.offsetHeight * -1;
    }
  },

  hideSticky: function (item, $node, className) {
    bcl.u.removeClass($node, className);
    item.offset = 0;
    $node.parentNode.style.height = "";

    if (item.callback) {
      item.callback(false, item.itemCallback || {});
    }
  },

  calcSpace: function (data) {
    if (!data.item.callbackValid || data.item.callbackValid(data.item.$node)) {
      if (bcl.s.sticky.props[data.nameProp] > 0) {
        data.item.$node.style[data.nameAttrStyle] = bcl.s.sticky.props[data.nameProp] + "px";
      }

      bcl.s.sticky.props[data.nameProp] += data.item.$node.offsetHeight;
    }
  },

  remove: function (index) {
    bcl.s.sticky.props.items.splice(index, 1);
    bcl.s.sticky.resize();
  },

  setMarginInHeader: function () {
    const $header = document.querySelector("body > header");

    if ($header) {
      $header.style.marginTop = bcl.s.sticky.props.marginHeader + "px";
    }
  },
  moveStickyTop: function (item) {
    const ribon = item?.$node?.firstElementChild;
    if (ribon && ribon.classList.contains("c-ribbon")) {
      ribon.style.bottom = "initial";
      ribon.style.top = "120px";
    }
  },

  launchSetStyleHero: function () {
    setTimeout(function () {
      bcl.s.sticky.setStyleIndHeroSlider();
    }, 300);
  },

  setStyleIndHeroSlider: function () {
    const $header = document.querySelector("body > header"),
      $heroComp = document.querySelector(".collapsed-header-js"),
      $heroCompParent = $heroComp ? $heroComp.closest(".heroslider") : "",
      heightCalc = "calc(100vh - " + bcl.s.sticky.props.marginHeader + "px)";

    if ((bcl.u.containsClass($header, "c-container-collapsed-desktop") || bcl.u.containsClass($header, "c-container-collapsed")) && $heroCompParent) {
      bcl.u.containsClass($heroCompParent, "mod--full-height-heroslider") && bcl.u.setStyleInElement($heroComp, "height", heightCalc);
    }
  },

  setTopInItems: function (item) {
    item.$node.style.top = bcl.s.sticky.props.marginHeader - item.$node.offsetHeight + "px";
  },

  setMarginInFooter: function () {
    const $footer = document.querySelector("body > footer");
    let value = bcl.s.sticky.props.marginFooter;

    if (value && value < 0) {
      value = 0;
    }

    if ($footer) {
      $footer.style.marginBottom = value + "px";
    }
  },
};

bcl.u.docReady(bcl.s.sticky.init, true);
bcl.u.resize(bcl.s.sticky.resize);

window.addEventListener("scroll", bcl.s.sticky.windowScrollEvent, false);
