import { dynamicImport, isPresent as isComponentPresent } from "components-utils";

const BhgComponent = ({ selector, path, initMethod = "init", skipInit = false, belongsTo = "", customName = "", forceImport = false }) => ({
  isPresent: () => forceImport || isComponentPresent(selector),
  importComponent: async ({ name, root }) => {
    const component = await import(/* webpackChunkName: "[request]" */ `./${path}.js`);
    return dynamicImport({
      name,
      customName,
      belongsTo,
      component,
      initMethod,
      skipInit,
      root,
    });
  },
});

const components = {
  assetVideo: BhgComponent({ selector: ".c-video", path: "asset-video", forceImport: true }),
  genericText: BhgComponent({ selector: ".cmp-text", path: "genericText" }),
};

export default components;
