import lazyVideoHelper from "./lazyVideos-helper";

const assetVideo = {
  props: {
    cssSelector: ".c-video",
  },

  //-----------------------------------------------------------------------//
  //----------------------------- 1. Init ---------------------------------//
  //-----------------------------------------------------------------------//
  init() {
    this.initVideos();
  },

  //-----------------------------------------------------------------------//
  //--------------------------- 2. initVideos -----------------------------//
  //-----------------------------------------------------------------------//
  initVideos(baseDom = document) {
    if (bcl.editMode) {
      return;
    }

    const baseFind = baseDom.className?.includes("video-generic") ? baseDom.parentNode : baseDom;
    const observerConfig = { childList: true, subtree: true };

    const processVideos = (base) => {
      const videos = base.querySelectorAll(".c-video--video-node:not([data-processed])");

      base.querySelectorAll(".swiper-wrapper[data-items-load] .c-video--video-node:not([data-processed])").forEach((video) => {
        video.setAttribute("vdautoplay", true);
        video.setAttribute("vdmuted", true);
      });

      videos.forEach((video) => {
        const dynamicMedia = video.getAttribute("videoDesktopPathDM") !== "DM-not-configured" || video.getAttribute("videoMobilePathDM") !== "DM-not-configured";
        video.setAttribute("dynamicMedia", dynamicMedia);
        video.setAttribute("vdcontrols", video.hasAttribute("vdcontrols"));
        video.setAttribute("vdmuted", video.hasAttribute("vdmuted"));
        video.setAttribute("data-processed", "true");
      });

      lazyVideoHelper.addLazyVideos(base);
      this.changeVideosSourceOnResize(videos);
    };

    processVideos(baseFind);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE && (node.querySelector(".c-video--video-node:not([data-processed])") || node.querySelector(".swiper-wrapper[data-items-load] .c-video--video-node:not([data-processed])"))) {
            processVideos(node);
          }
        });
      });
    });

    observer.observe(baseFind, observerConfig);

    this.cleanupObserver = () => observer.disconnect();
  },

  //-----------------------------------------------------------------------//
  //------------------------ 3. setupBestSource ---------------------------//
  //-----------------------------------------------------------------------//
  setupBestSource(video) {
    const dynamicMedia = video.getAttribute("dynamicMedia") === "true";
    let canPlayNativeDM = false;
    let sourceDesktop;
    let sourceMobile;

    if (dynamicMedia) {
      const appleSupport = video.canPlayType("application/vnd.apple.mpegurl");
      sourceDesktop = video.getAttribute("videoDesktopPathDM") !== "DM-not-configured" ? video.getAttribute("videoDesktopPathDM") : undefined;
      sourceMobile = video.getAttribute("videoMobilePathDM") !== "DM-not-configured" ? video.getAttribute("videoMobilePathDM") : undefined;

      canPlayNativeDM = appleSupport;
    } else {
      sourceDesktop = video.getAttribute("videoDesktopPath") || undefined;
      sourceMobile = video.getAttribute("videoMobilePath") || undefined;
    }

    const isMobile = bcl.u.mobile.isMobile();
    const source = isMobile ? sourceMobile || sourceDesktop : sourceDesktop || sourceMobile;

    video.setAttribute("lastDevice", isMobile ? "mobile" : "desktop");
    video.setAttribute("canPlayNativeDM", canPlayNativeDM);
    video.querySelector(".videoDynamicPath").src = source;
    video.querySelector(".videoDynamicPath").type = dynamicMedia ? "application/x-mpegURL" : "video/mp4";
  },

  //-----------------------------------------------------------------------//
  //------------------ 4. changeVideosSourceOnResize ----------------------//
  //-----------------------------------------------------------------------//
  changeVideosSourceOnResize(videos) {
    window.addEventListener("resize", () => {
      videos.forEach((video) => {
        const isMobile = bcl.u.mobile.isMobile();
        const lastDevice = video.getAttribute("lastDevice");

        if ((lastDevice === "desktop" && isMobile) || (lastDevice === "mobile" && !isMobile)) {
          this.setupBestSource(video);
          if (video.getAttribute("dynamicMedia") === "true" && video.getAttribute("canPlayNativeDM") === "true") {
            video.load();
          } else {
            lazyVideoHelper.customPlayerLoadAndPlay(video);
          }
        }
      });
    });
  },

  //-----------------------------------------------------------------------//
  //-------------------- 5. stopAllVideosWithSound ------------------------//
  //-----------------------------------------------------------------------//
  stopAllVideosWithSound(baseDom = document) {
    const GNvideos = baseDom.querySelectorAll(".video-generic");
    const YTvideos = baseDom.querySelectorAll(".video-youtube");
    const VMvideos = baseDom.querySelectorAll(".video-vimeo");

    GNvideos.forEach((element) => {
      if (element.getAttribute("vdautoplay") === "true" && element.getAttribute("vdmuted") && !element.getAttribute("vdcontrols")) {
        return;
      }
      element.pause();
    });

    YTvideos.forEach((element) => {
      element.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*");
    });

    VMvideos.forEach((element) => {
      element.contentWindow.postMessage('{"method":"pause"}', "*");
    });
  },
};

export default assetVideo;
