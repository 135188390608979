bcl.s.dataAnchor = {
  props: {
    offset: -100,
    classTabPanel: "cmp-tabs__tabpanel",
    classTab: "cmp-tabs__tab",
    classTabActive: "cmp-tabs__tab--active",
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document;

    const components = baseFind.querySelectorAll('a[href^="#"]');

    bcl.u.forEach(components, function ($component) {
      const href = bcl.s.dataAnchor.getHref($component);

      if (href) {
        $component.addEventListener("click", function () {
          setTimeout(function () {
            bcl.s.dataAnchor.clickAnchor(bcl.s.dataAnchor.getHref($component), bcl.s.dataAnchor.props.offset);
          }, 200);
        });
      }
    });

    window.addEventListener("preInitLoaded", function () {
      setTimeout(function () {
        bcl.s.dataAnchor.isAnchor();
      }, 1000);
    });
  },

  isAnchor: function () {
    const url = window.location.hash;

    if (url) {
      bcl.s.dataAnchor.clickAnchor(url.replace("#", ""), bcl.s.dataAnchor.props.offset);
    }
  },

  getHref: function ($component) {
    const hrefSplit = $component.href.split("#");

    if (hrefSplit.length == 2) {
      return hrefSplit[1];
    }
  },

  clickAnchor: function (valueAnchor, offset) {
    const $node = bcl.s.dataAnchor.getNodeAnchor(valueAnchor);

    if ($node) {
      const $parent = bcl.u.getParent($node, bcl.s.dataAnchor.props.classTabPanel);
      if (bcl.u.containsClass($parent, bcl.s.dataAnchor.props.classTabPanel)) {
        for (let i = 0; i < document.getElementsByClassName(bcl.s.dataAnchor.props.classTabPanel).length; i++) {
          const $tabPanel = document.getElementsByClassName(bcl.s.dataAnchor.props.classTabPanel)[i];
          const $tab = document.getElementsByClassName(bcl.s.dataAnchor.props.classTab)[i];
          if ($tabPanel === $parent && !bcl.u.containsClass($tab, bcl.s.dataAnchor.props.classTabActive)) {
            bcl.u.triggerEvent($tab, "click");
          }
        }
      }
      bcl.s.dataAnchor.goToScrollAnchor($node, offset);
    }
  },

  goToScrollAnchor: function ($node) {
    if ($node) {
      let lastPosition = $node.getBoundingClientRect().top;
      let lastScroll = 0;
      $node.scrollIntoView({ behavior: "smooth" });

      const interval = setInterval(() => {
        const currentPosition = $node.getBoundingClientRect().top;
        if (currentPosition !== lastPosition) {
          lastPosition = $node.getBoundingClientRect().top;
          $node.scrollIntoView({ behavior: "smooth" });
        } else {
          clearInterval(interval);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          window.removeEventListener("scroll", stopMovement);
        }
      }, 500);

      const stopMovement = () => {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if ((lastPosition > 0 && currentScroll < lastScroll) || (lastPosition < 0 && currentScroll < lastScroll)) {
          clearInterval(interval);
          window.removeEventListener("scroll", stopMovement);
        }
        lastScroll = currentScroll;
      };

      window.addEventListener("scroll", stopMovement);
    }
  },

  getScrollTop: function ($node) {
    const rect = $node.getBoundingClientRect();
    //return rect.top + $body.scrollTop;
    // TODO revisar este cambio
    return rect.top + (window.scrollY || document.documentElement.scrollTop);
  },

  getNodeAnchor: function (valueAnchor) {
    return document.querySelector('[data-anchor="' + valueAnchor + '"]');
  },
};

bcl.u.docReady(bcl.s.dataAnchor.init, true);
