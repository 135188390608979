const mybTriggerBanner = {
  props: {
    classTrigger: "c-myb-trigger-banner-JS",
    cssSelector: "cmp-bhg-myb-trigger-banner",
    cssSelectorSwiper: ".swiper-wrapper[data-items-load]",
    nameAttr: "data-items-load",
    classCompact: "mod--compact-carousel",

    config: {
      grabCursor: true,

      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: true,
      loopAdditionalSlides: 2,
      initialSlide: 0,
      spaceBetween: 10,

      breakpoints: {
        1024: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          initialSlide: 2,
        },
      },

      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      mousewheel: true,
      keyboard: true,
    },
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document,
      $containers = baseFind.getElementsByClassName(this.props.classTrigger),
      $compact = baseFind.querySelector("." + this.props.classCompact);

    if ($compact) {
      this.props.config.breakpoints = null;
      this.props.config.slidesPerView = 1;
      this.props.config.slidesPerGroup = 1;
      this.props.config.initialSlide = 0;
    }

    bcl.u.forEach($containers, ($container) => {
      this.bannerSwitcher($container);
    });
    bcl.u.swiper.init(baseFind, this.props);
  },

  bannerSwitcher: function ($container) {
    if ($container) {
      bcl.u.removeClass($container, "hidden");
    }
  },

  hideSwitcherContainer: function () {
    const container = document.querySelectorAll(".c-myb-trigger-banner-JS");
    if (container) {
      bcl.u.addClass(container[0], "hidden");
    }
  },
};

export default mybTriggerBanner;
