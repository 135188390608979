bcl.s.countdown = {
  props: {
    dataAttr: "countdown",
    animationClass: "count-animate",
    firstTime: [{}, {}, {}, {}],
  },

  init: function (baseDom) {
    (baseDom || document).querySelectorAll("[data-" + bcl.s.countdown.props.dataAttr + "]").forEach(function ($element) {
      bcl.s.countdown.process($element);
      bcl.s.countdown.countdownAnimation($element);
    });
  },

  process: function ($element) {
    const visibilityTarget = $element.closest(".hide-countdown-JS") || $element;
    const date = new Date($element.dataset[bcl.s.countdown.props.dataAttr]);
    if (date - new Date() < 1) {
      return;
    }
    const milisecondsLeft = date - new Date();
    const daysLeft = milisecondsLeft / (1000 * 60 * 60 * 24);
    let showWhenXDaysAreLeft = $element.dataset.showWhenXDaysAreLeft;
    if (typeof showWhenXDaysAreLeft !== "number") {
      showWhenXDaysAreLeft = ($element.closest("[data-show-when-x-days-are-left]") || { dataset: {} }).dataset.showWhenXDaysAreLeft;
    }
    if (showWhenXDaysAreLeft !== undefined) {
      showWhenXDaysAreLeft = showWhenXDaysAreLeft.length > 0 ? +showWhenXDaysAreLeft : showWhenXDaysAreLeft;
    }
    const startCountdown = function () {
      bcl.s.countdown.timer($element, date.getTime());
      setTimeout(function () {
        visibilityTarget.classList.remove("hidden");
      }, 1000);
    };
    if (typeof showWhenXDaysAreLeft === "number" && showWhenXDaysAreLeft < daysLeft && daysLeft < 2) {
      setTimeout(startCountdown, milisecondsLeft);
    } else if (typeof showWhenXDaysAreLeft === "number" && showWhenXDaysAreLeft >= daysLeft) {
      startCountdown();
    }
  },

  timer: function ($element, miliseconds) {
    const then = miliseconds;

    const countdown = setInterval(function () {
      const secondsLeft = Math.round((then - Date.now()) / 1000);

      if (secondsLeft <= 0) {
        clearInterval(countdown);
        bcl.s.countdown.finish($element);
      }

      bcl.s.countdown.displayTimeLeft($element, secondsLeft);
    }, 1000);
  },

  finish: function ($element) {
    const callback = $element.dataset.callback;

    if (callback) {
      bcl.u.executeFunctionByName(callback, window, $element);
    }
  },

  displayTimeLeft: function ($element, seconds) {
    let showPrev = false;
    const obj = [
      { selector: ".days", value: Math.floor(seconds / 86400) },
      { selector: ".hours", value: Math.floor((seconds % 86400) / 3600) },
      { selector: ".minutes", value: Math.floor(((seconds % 86400) % 3600) / 60) },
      { selector: ".seconds", value: seconds % 60 < 10 ? "0" + (seconds % 60) : seconds % 60 },
    ];
    for (let i = 0; i < obj.length; i++) {
      showPrev = bcl.s.countdown.displayTimeLeftElement($element, obj[i].selector, obj[i].value, showPrev);
      if (obj[i].value == 0) {
        if (obj[i - 1] && obj[i - 1].value == 0 && !bcl.s.countdown.props.firstTime[i - 1][i - 1] && !bcl.s.countdown.props.firstTime[i - 1].flag) {
          bcl.s.countdown.props.firstTime[i - 1].flag = true;
          bcl.s.countdown.countdownAnimation($element, true, obj[i - 1].selector);
        } else if (obj[i - 1] && obj[i - 1].value != 0) {
          bcl.s.countdown.props.firstTime[i - 1].flag = null;
        }
      }
    }
  },

  displayTimeLeftElement: function ($parent, selector, value, showPrev) {
    const $element = $parent.querySelector(selector);

    if (!$element) {
      return false;
    }

    if (value || showPrev) {
      $element.querySelector(".value").textContent = "" + value;
      return true;
    }

    return false;
  },

  countdownAnimation: function (container, init, selector) {
    const seconds = container.querySelector(".seconds .count-animation-js"),
      selectedContainer = container.querySelector(selector + " .count-animation-js");
    if (!init) {
      bcl.s.countdown.intervalAnimation(seconds, 1000);
    } else {
      bcl.s.countdown.displayAnimation(selectedContainer);
    }
  },

  intervalAnimation: function ($container, timer) {
    setInterval(function () {
      bcl.s.countdown.displayAnimation($container);
    }, timer);
  },

  displayAnimation: function ($container) {
    $container && bcl.u.addClass($container, bcl.s.countdown.props.animationClass);
    setTimeout(function () {
      $container && bcl.u.removeClass($container, bcl.s.countdown.props.animationClass);
    }, 120);
  },
};

bcl.u.docReady(bcl.s.countdown.init);
