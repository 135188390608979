bcl.c.flipcard = {
  props: {
    selectorFlipCardDetailsButton: ".c-flip-card-front-reveal-btn-JS",
    selectorFlipCardDetailsArea: ".c-flip-card-front-reveal-area-JS",
    selectorFlipCard: ".c-flip-card-JS",
    selectorFlipCardReverseRevealArea: ".c-flip-card-reverse-reveal-area-JS",
  },
  init: function (baseFind) {
    bcl.c.flipcard.initListener(baseFind);
  },
  initListener: function (baseFind) {
    const $container = baseFind ? baseFind : document,
      flipButtons = $container.querySelectorAll(bcl.c.flipcard.props.selectorFlipCardDetailsButton);
    bcl.u.forEach(flipButtons, function (flipButton) {
      const flipCard = flipButton.closest(bcl.c.flipcard.props.selectorFlipCard);
      flipButton.addEventListener("click", function () {
        bcl.u.removeClass(flipCard, "mod--unflip");
        bcl.u.addClass(flipCard, "mod--flip");
      });
      flipCard.querySelector(bcl.c.flipcard.props.selectorFlipCardDetailsArea).addEventListener("click", function () {
        bcl.u.removeClass(flipCard, "mod--unflip");
        bcl.u.addClass(flipCard, "mod--flip");
      });
      flipCard.querySelector(bcl.c.flipcard.props.selectorFlipCardReverseRevealArea).addEventListener("click", function () {
        bcl.u.removeClass(flipCard, "mod--flip");
        bcl.u.addClass(flipCard, "mod--unflip");
      });
    });
  },
};

bcl.preInit.push(bcl.c.flipcard.init);
