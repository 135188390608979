const headerCommonProps = {
  $container: null,
  classNameActiveBody: "activeMegamenu",
  classNameActive: "mod--active",
};

const megaMenuCommonProps = {
  classNameMenuBtn: "c-header__switch-btn",
};

export { headerCommonProps, megaMenuCommonProps };
