// NECESSARY FOR FASTBOOKING (HIGHEST PRIORITY)
import "./js/shared/tabsContainer.js";
import "./js/shared/loadForm.js";

// Init Components and Shared dynamically
import BaseComponents from "./components";
import BhgComponents from "bhg-components";
import BarceloComponents from "barcelo-components-init";
import { BarceloShared } from "barcelo-shared-init";

document.addEventListener("DOMContentLoaded", async () => {
  await BarceloComponents.init({ ...BhgComponents, ...BaseComponents });
  BarceloShared.init();
  window.dispatchEvent(new Event("bcl-base-components-loaded"));
});

// BARCELO";
import "./js/utils/cookieUtils.js";
import "./js/utils/utag.js";
import "./js/general.js";

//EMBED CODE
import "../../components/bhg/accordion";
import "../../components/bhg/tabs";

// Shared JS";
import "./js/shared/modal.js";
import "./js/shared/modalMeetingRoom.js";
import "./js/shared/sticky.js";
import "./js/shared/profile.js";
import "./js/shared/progressBar.js";
import "./js/shared/translate-on-the-fly.js";

//js my barcelo
import "../../components/barcelo/mybarcelo/myb-edit-profile/js/c-myb-edit-profile.js";
import "../../components/barcelo/mybarcelo/myb-trigger-banner/js/c-myb-trigger-banner.js";

import "./js/shared/countdown.js";
import "./js/shared/promo-countdown.js";
import "./js/shared/currency.js";
import "./js/shared/dataAnchor.js";
import "./js/shared/hotelReturn.js";
import "./js/shared/scrollUp.js";
import "./js/shared/searchHotel.js";
import "./js/shared/tabs.js";
import "./js/shared/recaptchav3.js";

// Shared JS with use components";
import "./js/shared/tooltip.js";
import "./js/shared/rte-tooltip.js";
import "./js/shared/360.js";
import "./js/shared/flipcard.js";
import "./js/shared/globalEvent.js";

// VENDORS";
import "./libraries/datepicker/fecha.min.js";
import "./libraries/datepicker/hotel-datepicker.js";

// Other Components
import "../../components/barcelo/forms/logged-update-form/js/logged-update-form.js";
