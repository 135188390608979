bcl.c.accordion = {
  init: function () {
    document.querySelectorAll(".accordion-item").forEach((component) => {
      const selectedAccordion = component.querySelector("div[data-selected-accordion]")?.dataset.selectedAccordion;
      if (selectedAccordion) {
        component.querySelectorAll(".cmp-anchor-id-JS").forEach((anchorElement) => {
          if (anchorElement.dataset.anchorId === selectedAccordion) {
            const accordionItem = bcl.u.closest(anchorElement, ".accordion-item");
            if (accordionItem) {
              const accordionToggle = accordionItem.querySelector(".accordion-toggle-JS");
              if (accordionToggle) {
                accordionToggle.click();
                return; // Exit the loop once the selected accordion is toggled
              }
            }
          }
        });
      }
    });
  },
};

bcl.u.docReady(bcl.c.accordion.init);
