bcl.s.promoCountdown = {
  props: {
    animationClass: "count-animate",
    firstTime: [{}, {}, {}, {}],
  },
  init: function () {
    bcl.s.promoCountdown.utils.renderCountdown();
  },
  utils: {
    renderCountdown: function () {
      document.querySelectorAll(`.promo-countdown-JS`).forEach(($element) => {
        if ($element.classList.contains("force-countdown-JS")) {
          bcl.s.promoCountdown.utils.processForced($element);
        } else {
          bcl.s.promoCountdown.utils.process($element);
        }
        bcl.s.promoCountdown.utils.countdownAnimation($element);
        $element.classList.remove("promo-countdown-JS");
      });
    },
    getElement: function ($element, selector) {
      return $element.closest(selector) || $element.querySelector(selector) || $element.parentElement.querySelector(selector);
    },
    getAttribute: function ($element, attributeName) {
      return ($element.closest(`[${attributeName}]`) || $element).getAttribute(attributeName) || ($element.querySelector(`[${attributeName}]`) || $element).getAttribute(attributeName);
    },
    process: function ($element) {
      const visibilityTarget = bcl.s.promoCountdown.utils.getElement($element, ".hide-countdown-JS") || $element;

      const bookingStartDate = new Date(bcl.s.promoCountdown.utils.getAttribute($element, "data-booking-start"));
      const bookingEndDate = new Date(bcl.s.promoCountdown.utils.getAttribute($element, "data-booking-end"));
      const travelStartDate = new Date(bcl.s.promoCountdown.utils.getAttribute($element, "data-travel-start"));
      const travelEndDate = new Date(bcl.s.promoCountdown.utils.getAttribute($element, "data-travel-end"));
      let showWhenXDaysAreLeft = bcl.s.promoCountdown.utils.getAttribute($element, "data-show-when-x-days-are-left");
      showWhenXDaysAreLeft = showWhenXDaysAreLeft === "INFINITY" ? Infinity : parseInt(showWhenXDaysAreLeft);
      const countdownText = bcl.s.promoCountdown.utils.getAttribute($element, "data-promo-countdown-text");
      const countdownTo = bcl.s.promoCountdown.utils.getAttribute($element, "data-promo-countdown-to");
      if (bookingStartDate.getDate() && bookingEndDate.getDate() && travelStartDate.getDate() && travelEndDate.getDate() && showWhenXDaysAreLeft !== undefined && !isNaN(showWhenXDaysAreLeft) && countdownTo) {
        const today = new Date();
        let countdownIsValid = false;
        let targetDate;
        switch (countdownTo) {
          case "booking_start":
            countdownIsValid = today < bookingStartDate;
            targetDate = bookingStartDate;
            break;
          case "booking_end":
            countdownIsValid = today > bookingStartDate && today < bookingEndDate;
            targetDate = bookingEndDate;
            break;
          case "travel_start":
            countdownIsValid = today < travelStartDate;
            targetDate = travelStartDate;
            break;
          case "travel_end":
            countdownIsValid = today > travelStartDate && today < travelEndDate;
            targetDate = travelEndDate;
            break;
        }

        const $promoCountdownLabel = bcl.s.promoCountdown.utils.getElement($element, ".promo-countdown-text-JS");
        if ($promoCountdownLabel) {
          $promoCountdownLabel.textContent = countdownText;
        }

        if (countdownIsValid) {
          const millisecondsLeft = targetDate - today;
          const daysLeft = millisecondsLeft / (1000 * 60 * 60 * 24);
          const startCountdown = function () {
            bcl.s.promoCountdown.utils.timer($element, targetDate.getTime());
            setTimeout(function () {
              visibilityTarget.classList.remove("hidden");
            }, 1000);
          };
          if (showWhenXDaysAreLeft && showWhenXDaysAreLeft >= daysLeft) {
            startCountdown();
          } else if (daysLeft < 1) {
            setTimeout(startCountdown, millisecondsLeft);
          }
        }
      }
    },
    processForced: function ($element) {
      const visibilityTarget = bcl.s.promoCountdown.utils.getElement($element, ".hide-countdown-JS") || $element;

      const today = new Date();
      const countdown = new Date(bcl.s.promoCountdown.utils.getAttribute($element, "data-countdown"));
      if (countdown && countdown > today) {
        bcl.s.promoCountdown.utils.timer($element, countdown.getTime());
        setTimeout(function () {
          visibilityTarget.classList.remove("hidden");
        }, 1000);
      }
    },
    countdownAnimation: function ($element) {
      const seconds = bcl.s.promoCountdown.utils.getElement($element, ".seconds .count-animation-js");
      bcl.s.promoCountdown.utils.intervalAnimation(seconds, 1000);
    },
    timer: function ($element, milliseconds) {
      const then = milliseconds;
      const countdown = setInterval(function () {
        const secondsLeft = Math.round((then - Date.now()) / 1000);

        if (secondsLeft <= 0) {
          clearInterval(countdown);
          bcl.s.promoCountdown.utils.finish($element);
        }

        bcl.s.promoCountdown.utils.displayTimeLeft($element, secondsLeft);
      }, 1000);
    },
    finish: function ($element) {
      const callback = bcl.s.promoCountdown.utils.getAttribute($element, "data-callback");
      if (callback) {
        bcl.u.executeFunctionByName(callback, window, $element);
      }
    },
    displayTimeLeft: function ($element, seconds) {
      let showPrev = false;
      const timeObjects = [
        { selector: ".days", value: Math.floor(seconds / 86400) },
        { selector: ".hours", value: Math.floor((seconds % 86400) / 3600) },
        { selector: ".minutes", value: Math.floor(((seconds % 86400) % 3600) / 60) },
        { selector: ".seconds", value: seconds % 60 },
      ];
      if (timeObjects[0].value === 0) {
        $element.querySelector(timeObjects[0].selector).remove();
        timeObjects.shift();
      }
      timeObjects.forEach(function (timeObject, i) {
        showPrev = bcl.s.promoCountdown.utils.displayTimeLeftElement($element, timeObject.selector, timeObject.value, showPrev);
        if (timeObject.value === 0) {
          if (timeObjects[i - 1] && timeObject.value === 0 && !bcl.s.promoCountdown.props.firstTime[i - 1][i - 1] && !bcl.s.promoCountdown.props.firstTime[i - 1].flag) {
            bcl.s.promoCountdown.props.firstTime[i - 1].flag = true;
            bcl.s.promoCountdown.utils.countdownAnimation($element, true, timeObjects[i - 1].selector);
          } else if (timeObjects[i - 1] && timeObjects[i - 1].value !== 0) {
            bcl.s.promoCountdown.props.firstTime[i - 1].flag = null;
          }
        }
      });
    },

    displayTimeLeftElement: function ($parent, selector, value, showPrev) {
      const $element = $parent.querySelector(selector);

      if (!$element) {
        return false;
      }
      if ((typeof value == "number" && !isNaN(value)) || showPrev) {
        $element.querySelector(".value").textContent = (value < 10 ? "0" : "") + value;
        return true;
      }

      return false;
    },

    intervalAnimation: function ($container, timer) {
      setInterval(function () {
        bcl.s.promoCountdown.utils.displayAnimation($container);
      }, timer);
    },

    displayAnimation: function ($container) {
      $container && bcl.u.addClass($container, bcl.s.promoCountdown.props.animationClass);
      setTimeout(function () {
        $container && bcl.u.removeClass($container, bcl.s.promoCountdown.props.animationClass);
      }, 120);
    },
  },
};

bcl.u.docReady(bcl.s.promoCountdown.init);
