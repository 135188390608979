bcl.s.progressBar = {
  props: {
    classNameMilestone: "c-progress-bar__milestone",
    classNameMilestoneBar: ".c-progress-bar__milestone-bar",
    classActive: "mod--active",
    classLastActive: "mod--last-active",
    selectorFaqParent: ".block-quest-faq-js.hidden",
  },

  updateClass: function (stays, $container, sent) {
    if (stays === null || stays === undefined || !$container) {
      return;
    }

    const $nodes = $container.getElementsByClassName(bcl.s.progressBar.props.classNameMilestone);

    if (!$nodes) {
      return;
    }

    const value = 0.5;
    let count = 0;

    if (sent == -1) {
      count = ($nodes.length - 1) * value;
    }
    if ($nodes.length < stays) {
      stays = $nodes.length - 1;
    }
    if ($nodes.length > 0) {
      bcl.s.progressBar.showParentFaqOption($nodes[0]);
      setTimeout(function () {
        bcl.s.progressBar.updateInOrder($nodes, 0, count, value, sent, stays);
      }, 100);
    }
  },

  updateInOrder: function ($nodes, nodeIndex, count, value, sent, stays) {
    const $element = $nodes[nodeIndex];
    bcl.s.progressBar.clearClass($element);
    const bar = $element.querySelector(bcl.s.progressBar.props.classNameMilestoneBar);

    if (bar) {
      count += value * sent;
      bar.style = "--delay-var: " + count + "s";
    }

    if (nodeIndex <= stays) {
      bcl.u.addClass($element, bcl.s.progressBar.props.classActive);
    }

    if ($nodes[stays] === $nodes[nodeIndex]) {
      bcl.u.addClass($nodes[stays], bcl.s.progressBar.props.classLastActive);
    }

    if ($nodes[nodeIndex + 1]) {
      $element.addEventListener("transitionend", () => {
        bcl.s.progressBar.updateInOrder($nodes, nodeIndex + 1, count, value, sent, stays);
      });
    }
  },
  showParentFaqOption: function ($element) {
    const $faqParent = $element.closest(bcl.s.progressBar.props.selectorFaqParent);
    if ($faqParent) {
      bcl.u.removeClass($faqParent, "hidden");
    }
  },
  clearClass: function ($node) {
    bcl.u.removeClass($node, bcl.s.progressBar.props.classActive);
    bcl.u.removeClass($node, bcl.s.progressBar.props.classLastActive);
  },
};
