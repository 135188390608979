bcl.s.loadForm = {
  props: {
    formsScriptLoaded: false,
    formsStylesheetLoaded: false,
  },

  isStyleSheetLoaded: function (href) {
    const links = document.querySelectorAll('link[rel="stylesheet"]');
    for (let i = 0; i < links.length; i++) {
      if (links[i].href.match(href)) {
        return true;
      }
    }
    return false;
  },

  isScriptLoaded: function (src) {
    const scripts = document.getElementsByTagName("script");
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src.match(src)) {
        return true;
      }
    }
    return false;
  },

  init: function (baseDom) {
    const baseFind = baseDom || document;
    const elements = baseFind.getElementsByClassName("form-JS");

    if (elements && elements.length) {
      const cssHref = "/etc.clientlibs/barcelo/clientlibs/others/clientlib-forms.min.css";
      const jsSrc = "/etc.clientlibs/barcelo/clientlibs/others/clientlib-forms.min.js";

      if (!this.isStyleSheetLoaded(cssHref) && !this.props.formsStylesheetLoaded) {
        bcl.u.loadStyleSheet(cssHref);
        this.props.formsStylesheetLoaded = true;
      } else {
        console.log("Stylesheet already loaded:", cssHref);
      }

      if (!this.isScriptLoaded(jsSrc) && !this.props.formsScriptLoaded) {
        bcl.u.loadScript({
          src: jsSrc,
          callback: bcl.s.loadForm.loadJS,
          type: "text/javascript",
          defer: true,
        });
        this.props.formsScriptLoaded = true;
      } else {
        console.log("Script already loaded:", jsSrc);
      }
    }
  },

  loadJS: function () {
    bcl.u.preInit();

    const editProfileV2 = document.querySelector(".js-myb-edit-profile-v2");

    if (editProfileV2) {
      bcl.c.mybEditProfileV2.init();
    } else {
      bcl.c.mybEditProfile.init();
    }
  },
};

const containerToObserve = document;

// Options for the observer
const options = {
  childList: true,
  subtree: true,
};

// Check and watch images
const callback = function (mutationList) {
  mutationList.forEach((mutation) => {
    mutation.addedNodes.forEach(function (node) {
      if (node.nodeType === Node.ELEMENT_NODE && document.querySelector(".form-JS")) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        observer.disconnect();
        bcl.s.loadForm.init();
      }
    });
  });
};

const observer = new MutationObserver(callback);
observer.observe(containerToObserve, options);
